import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'

interface LoginProps {
  onLogout(): void
}

const Logout = ({ onLogout }: LoginProps) => {
  const doLogout = useCallback(async () => {
    try {
      const result = await firebase.auth().signOut()
      console.log(result)
      onLogout()
    } catch (error) {
      console.error('Error signing out', error)
    }
  }, [onLogout])
  return (
    <Button onClick={doLogout} color="inherit">
      Logout
    </Button>
  )
}

export default Logout
