import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Logo } from './Logo'
import Login from './Login'
import Logout from './Logout'

const Auth = ({ setAuthed }: { setAuthed: Function }) => {
  const [isCheckingUser, setIsCheckingUser] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    setIsCheckingUser(true)
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        setLoggedIn(!!firebase.auth().currentUser)
      })
      .finally(() => setIsCheckingUser(false))
  }, [])

  const provider = useMemo(() => new firebase.auth.GoogleAuthProvider(), [])

  useEffect(() => {
    setAuthed(isLoggedIn)
  }, [setAuthed, isLoggedIn])

  const onLogin = useCallback(
    (user: firebase.User, credentials: firebase.auth.AuthCredential) => {
      setLoggedIn(true)
    },
    [setLoggedIn]
  )

  if (isCheckingUser) {
    return null
  }

  return (
    <AppBar position="static">
      <TopNav>
        <LogoContainer>
          <Logo primary="#FFFFFF" />
        </LogoContainer>
        <NavSpacer />
        {isLoggedIn ? <Logout onLogout={() => setLoggedIn(false)} /> : <Login provider={provider} onLogin={onLogin} />}
      </TopNav>
    </AppBar>
  )
}

const TopNav = styled(Toolbar)`
  background-color: rgb(46, 54, 67);
`

const NavSpacer = styled.div`
  flex-grow: 1;
`

const LogoContainer = styled.div`
  padding: 16px;
  svg {
    width: 153px;
    height: 22px;
  }
`

export default Auth
