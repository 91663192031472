export type Device = {
  serial: string
  store: string
  type: string
  mode: string
  active: boolean
  name: string
  configRaw: any
  actionRaw: any
  lastSeen?: Date
  lastAction?: Date
}

export const isDeviceActive = (lastSeen: Date | undefined) => {
  if ( !lastSeen ) {
    return false
  }
  const currentDate = new Date()
  const inactiveLimit = 60 * 5.5
  return (currentDate.getTime() - lastSeen.getTime()) / 1000 < inactiveLimit
}

const mapDocsToDevices = (docs: firebase.firestore.QueryDocumentSnapshot[]) =>
  docs.map((doc) => {
    const { config, action } = doc.data()
    const active = isDeviceActive(config.lastSeen && config.lastSeen.toDate())
    return {
      serial: config.serial,
      store: config.storecode,
      type: config.deviceType,
      mode: config.deviceMode,
      name: config.displayName || '',
      lastAction: config.lastAction && config.lastAction.toDate() || '',
      lastSeen: config.lastSeen && config.lastSeen.toDate(),
      configRaw: config,
      actionRaw: action,
      active
    }
  })

export const getDevices = async () => {
  const query = await firebase
    .firestore()
    .collection('devices')
    .get()
  return mapDocsToDevices(query.docs)
}

export const listenToDevices = (callback: any) => {
  firebase
    .firestore()
    .collection('devices')
    .onSnapshot({}, ({ docs }) => {
      callback(mapDocsToDevices(docs))
    })
}
