import React, { useState } from 'react'
import DeviceTable from './components/DeviceTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import styled from 'styled-components'
import Auth from './components/Auth'

const App: React.FC = () => {
  const [authed, setAuthed] = useState(false)

  return (
    <AppContainer>
      <Auth setAuthed={setAuthed} />
      <CssBaseline />
      {authed && <DeviceTable />}
    </AppContainer>
  )
}

const AppContainer = styled.div`
  background: linear-gradient(45deg, rgb(23, 27, 39), rgb(36, 44, 57));

  width: 100%;
  min-height: 100vh;
`

export default App
