import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import { sortBy } from 'underscore'
import Modal from '@material-ui/core/Modal'
import TimeAgo from 'react-timeago'

import { Device, getDevices, listenToDevices, isDeviceActive } from '../services/FirebaseClient'

const DeviceTable = () => {
  const columnOrder = ['serial', 'store', 'type', 'mode', 'name', 'lastAction', 'active']
  const [devices, setDevices] = useState<Device[]>([])
  const [sortedDevices, setSortedDevices] = useState<Device[]>(devices)
  const [activeColumn, setActiveColumn] = useState<string>(columnOrder[0])
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc')
  const [filterString, setFilterString] = useState('')
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null)

  const updateDevices = useCallback(() => {
    setDevices(devices.map(device => ({ ...device, active: isDeviceActive(device.lastSeen) })))
  }, [devices])

  const intervalRef: any = useRef()

  const updateRate = 1000 * 10

  useEffect(() => {
    listenToDevices(setDevices)
  }, [])

  useEffect(() => {
    intervalRef.current = setInterval(updateDevices, updateRate)
    return () => intervalRef.current && clearInterval(intervalRef.current)
  }, [updateDevices])

  const createSortHandler = (index: number) => {
    return {
      onClick: () => {
        activeColumn === columnOrder[index]
          ? sortOrder === 'desc'
            ? setSortOrder('asc')
            : setSortOrder('desc')
          : setActiveColumn(columnOrder[index])
      },

      direction: sortOrder,
      active: activeColumn === columnOrder[index]
    }
  }

  useEffect(() => {
    const sorted = sortBy(devices.map(device => ({ ...device, active: device.active ? 1 : 0 })), activeColumn).map(
      device => ({ ...device, active: !!device.active })
    )
    setSortedDevices(sortOrder === 'desc' ? sorted : sorted.reverse())
  }, [activeColumn, sortOrder, devices])

  return (
    <>
      <DeviceTableContainer maxWidth="lg">
        <DeviceTableContent>
          <h1>Connected Devices</h1>
          <UIContainer>
            <SearchContainer>
              <TextField value={filterString} onChange={({ target }) => setFilterString(target.value)} />
              <SearchIcon />
            </SearchContainer>
            <LastUpdated>
              <Fab size="small" style={{ marginLeft: '16px' }} onClick={updateDevices}>
                <RefreshIcon />
              </Fab>
            </LastUpdated>
          </UIContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel {...createSortHandler(0)}>Serial #</TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel {...createSortHandler(1)}>Store</TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel {...createSortHandler(2)}>Type</TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel {...createSortHandler(3)}>Mode</TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel {...createSortHandler(4)}>Device Name</TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel {...createSortHandler(4)}>Last Action Seen</TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel {...createSortHandler(5)}>Status</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDevices
                .filter(dev => {
                  if (!filterString) {
                    return true
                  }
                  return Object.values(dev).filter(
                    val => typeof val == 'string' && val.toLowerCase().includes(filterString.toLowerCase())
                  ).length
                })
                .map(data => (
                  <DeviceTableRow onClick={() => setSelectedDevice(data)} key={data.serial}>
                    <TableCell>{data.serial}</TableCell>
                    <TableCell align="right">{data.store}</TableCell>
                    <TableCell align="right">{data.type}</TableCell>
                    <TableCell align="right">{data.mode}</TableCell>
                    <TableCell align="right">{data.name}</TableCell>
                    <TableCell align="right">
                    <TimeAgo date={data.lastAction ? data.lastAction.toLocaleString('en-US') : ``}/>
                    </TableCell>
                    <TableCell align="right">
                      <StatusIndicator
                        status={data.active}
                        title={
                          data.lastSeen
                            ? `Last seen @ ${data.lastSeen.toLocaleString('en-US')}`
                            : `This device has not reported a heartbeat`
                        }
                      />
                    </TableCell>
                  </DeviceTableRow>
                ))}
            </TableBody>
          </Table>
        </DeviceTableContent>
      </DeviceTableContainer>
      <DeviceDetailsModal open={!!selectedDevice} onClose={() => setSelectedDevice(null)}>
        <DeviceDetailsContent>
          {selectedDevice && (
            <>
              <h3>Action</h3>
              <RawContainer>
                <pre>
                  <code>{JSON.stringify(selectedDevice.actionRaw, null, 2)}</code>
                </pre>
              </RawContainer>
              <h3>Config</h3>
              <RawContainer>
                <pre>
                  <code>{JSON.stringify(selectedDevice.configRaw, null, 2)}</code>
                </pre>
              </RawContainer>
            </>
          )}
        </DeviceDetailsContent>
      </DeviceDetailsModal>
    </>
  )
}

const RawContainer = styled(Paper)`
  background: #f9f9f9 !important;
  border-radius: 4px;
  padding: 16px;
  max-height: 400px;
  overflow: auto;
`

const UIContainer = styled.div`
  display: flex;
  align-items: space-between;
  width: 100%;
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`

const DeviceTableContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
`

const DeviceTableContent = styled(Paper)`
  padding: 32px;
  overflow-x: scroll;
`

const LastUpdated = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const blink = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
`

const StatusIndicator = styled.div<{ status: boolean }>`
  width: 15px;
  height: 15px;
  background-color: ${({ status }) => (status ? '#28a745' : '#dc3545')}
  border-radius: 100%;
  float: right;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    content: "";
    background-color: inherit;
    animation: ${blink} 1.5s ease-out infinite;
  }
`

const DeviceTableRow = styled(TableRow)`
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    background: #f2f2f2;
  }
`

const DeviceDetailsContent = styled(Paper)`
  position: absolute;
  max-width: 800px;
  padding: 32px;
  outline: 0;

  @media screen and (max-width: 767px) {
    max-width: 95%;
  }
`

const DeviceDetailsModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export default DeviceTable
